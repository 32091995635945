<template>
	<!-- TODO: remove $emit('close'), after this modal is only used by BuilderModals system. -->
	<ZyroModal
		max-width="868px"
		max-height="600px"
		@close-modal="closeModal(), $emit('close')"
	>
		<section
			class="asset-manager"
			@dragenter="onUserImageDragEnter"
			@dragover.prevent="onUserImageDragEnter"
			@dragleave="onUserImageDragLeave"
			@drop.prevent="onUserImageDrop"
		>
			<span class="title">
				{{ $t('builder.assetManager.title') }}
			</span>

			<section class="header">
				<nav class="tabs">
					<button
						data-qa="builder-sectionsettings-tab-websiteimages"
						:class="{ 'is-active': !showUnsplash }"
						class="tab"
						@click="showUnsplash = false"
					>
						{{ $t('builder.assetManager.tabs.sitePhotos') }}
					</button>
					<button
						:class="{ 'is-active': showUnsplash }"
						class="tab"
						@click="showUnsplash = true"
					>
						{{ $t('builder.assetManager.tabs.freePhotos') }}
					</button>
				</nav>
				<div
					class="search-box"
					:class="{ 'is-focused': unsplashInputFocused }"
					:style="!showUnsplash && 'visibility: hidden;'"
				>
					<input
						v-model="searchTerm"
						:placeholder="$t('common.search')"
						@focus="unsplashInputFocused = true"
						@blur="unsplashInputFocused = false"
					>
					<span
						v-show="searchTerm !== ''"
						class="clear-input"
						@click="searchTerm = ''"
					>
						&times;
					</span>
				</div>
			</section>

			<section
				v-if="user"
				v-show="!showUnsplash"
				ref="dropArea"
				class="images-zyro-section"
				:class="{ 'is-loading': isLoading }"
			>
				<div
					v-if="uploadError"
					class="upload-error"
				>
					<ZyroSvg
						name="circle-alert"
						class="upload-error-icon"
					/>
					{{ uploadError }}
				</div>
				<div class="images-zyro">
					<ZyroLoader
						v-show="isLoading"
						class="user-loader"
					/>

					<div
						class="uploader"
						@click="openFileDialog"
					>
						<div
							v-show="!isLoading"
							class="uploader-placeholder"
						>
							<ZyroSvg name="picture" />
							<span class="uploader-text">
								{{ $t('common.dragAndDrop') }}
							</span>
							<span class="uploader-subtext">
								{{ $t('builder.assetManager.user.subtext') }}
							</span>
						</div>
						<div
							v-show="isLoading"
							ref="uploaderPlaceholder"
							class="uploader-placeholder"
						/>
						<form
							method="post"
							enctype="multipart/form-data"
							@submit.prevent="uploadToS3"
						>
							<input
								ref="userImage"
								style="display: none;"
								type="file"
								accept="image/jpeg,image/png,image/svg+xml,image/gif,image/webp"
								name="image"
								@change="uploadToS3"
							>
							<button
								data-qa="gallery-upload-btn"
								class="z-button round outline uploader-button"
							>
								{{ $t('builder.assetManager.user.btn') }}
							</button>
						</form>
					</div>
					<masonry
						v-if="userResults.length"
						class="images"
						:cols="2"
						:gutter="5"
					>
						<article
							v-for="(image, index) in userResults"
							:key="`${image.id}-${index}`"
							class="image"
						>
							<img :src="image">
							<div class="image-info">
								<div class="image-select">
									<button @click="selectImage(image)">
										{{ $t('common.select') }}
									</button>
								</div>
							</div>
						</article>
					</masonry>
					<span
						v-else
						class="no-images"
					>
						{{ $t('builder.assetManager.user.message') }}
					</span>
				</div>
			</section>

			<section
				v-else
				v-show="!showUnsplash"
				class="log-in"
			>
				<span class="log-in__message">
					{{ $t('builder.assetManager.noUser.message') }}
				</span>
				<div>
					<ZyroButton
						class="log-in__button"
						data-qa="builder-imagegallery-btn-signin"
						theme="outline"
						@click="redirectToWWWSignIn"
					>
						{{ $t('common.signIn') }}
					</ZyroButton>
					<ZyroButton
						class="log-in__button"
						data-qa="builder-imagegallery-btn-joinzyro"
						theme="primary"
						@click="redirectToWWWSignUp"
					>
						{{ $t('common.joinZyro') }}
					</ZyroButton>
				</div>
			</section>

			<section
				v-show="showUnsplash"
				ref="imagesContainer"
				class="images-unsplash"
				@scroll="loadMoreFromUnsplash"
			>
				<masonry
					class="images"
					:cols="3"
					:gutter="5"
				>
					<article
						v-for="(image, index) in unsplashResults.results"
						:key="`${image.id}-${index}`"
						class="image"
					>
						<img
							:src="image.urls.thumb"
							:alt="image.alt_description"
						>
						<div class="image-info">
							<div class="image-select">
								<button @click="selectImage(image)">
									{{ $t('common.select') }}
								</button>
							</div>
							<a
								class="author"
								:href="`https://unsplash.com/@${image.user.username}?utm_source=zyro&utm_medium=referral`"
								target="_blank"
							>
								<img
									:src="image.user.profile_image.small"
									alt
									class="author-thumb"
								>
								<div class="author-container">
									<span class="author-name">
										{{ `${image.user.first_name} ${image.user.last_name}` }}
									</span>
									<span class="author-username">@{{ image.user.username }}</span>
								</div>
							</a>
						</div>
					</article>
				</masonry>
				<span
					v-show="noResultsFound && !searchTerm && !isLoading"
					class="no-images"
				>
					{{ $t('builder.assetManager.searchBox.message') }}
				</span>
				<span
					v-show="noResultsFound && searchTerm && !isLoading"
					class="no-images"
				>
					<i18n path="builder.assetManager.searchBox.noResults">
						<span
							class="search-example"
							@click="searchTerm = $t('builder.assetManager.searchBox.searchTerm')"
						>
							{{ searchTerm }}
						</span>
					</i18n>
				</span>
				<ZyroLoader
					v-show="isLoading"
					class="loader"
				/>
			</section>

			<section
				v-show="showUnsplash"
				class="unsplash-attribution-container"
				@mouseleave="showUnsplashTerms = false"
			>
				<div class="unsplash-attribution">
					<span class="text">{{ $t('builder.assetManager.logoText') }}</span>
					<ZyroSvg
						class="unsplash-icon"
						name="unsplash"
					/>
				</div>
			</section>
		</section>
	</ZyroModal>
</template>

<script>
import axios from 'axios';
import loadImage from 'blueimp-load-image';
import {
	mapState,
	mapMutations,
} from 'vuex';

import { getAssets } from '@/api/AssetsApi';
import {
	searchImages,
	getRandom,
} from '@/api/UnsplashApi';
import {
	mapActionsGui,
	CLOSE_MODAL,
} from '@/store/builder/gui';
import { useRedirects } from '@/use/useRedirects';
import { debounce } from '@/utils/debounce';
import { getExtension } from '@/utils/modifyString';

export default {
	setup() {
		const {
			redirectToWWWSignUp,
			redirectToWWWSignIn,
		} = useRedirects();

		return {
			redirectToWWWSignUp,
			redirectToWWWSignIn,
		};
	},
	data() {
		return {
			searchTerm: '',
			unsplashResults: [],
			userResults: [],
			currentPage: 1,
			isLoading: false,
			showUnsplash: true,
			showUnsplashTerms: false,
			unsplashInputFocused: false,
			isLoggedIn: false,
			uploadError: '',
		};
	},
	computed: {
		...mapState('user', ['user']),
		...mapState('gui', ['activeModalSettings']),
		noResultsFound() {
			return Array.isArray(this.unsplashResults) || this.unsplashResults.results.length === 0;
		},
		// TODO: add user login check
	},
	watch: {
		searchTerm(newTerm) {
			this.isLoading = true;
			if (!newTerm) {
				this.isLoading = false;
			} else {
				this.searchUnsplash();
			}

			this.unsplashResults = [];
			this.currentPage = 1;
		},
	},
	async mounted() {
		try {
			this.unsplashResults = await getRandom({});
		} catch {
			// TODO: Proper error handling
		} finally {
			this.isLoading = false;
		}

		getAssets().then((result) => {
			this.userResults = result.data;
		});
	},
	methods: {
		...mapMutations(['setElementData']),
		...mapActionsGui({ closeModal: CLOSE_MODAL }),
		searchUnsplash: debounce(async function debouncedFunction() {
			if (!this.searchTerm) return;
			this.currentPage = 1;
			this.isLoading = true;
			try {
				this.unsplashResults = await searchImages(
					{
						query: this.searchTerm,
						page: this.currentPage,
					},
				);
			} catch {
				// TODO: Proper error handling
			} finally {
				this.isLoading = false;
			}
		}, 500),
		selectImage(newImage) {
			if (this.showUnsplash) {
				axios.post(`${process.env.VUE_APP_API_URL}/unsplash/download/`, { imageJson: newImage }, 'application/json');
			}

			const imageUrl = this.showUnsplash
				? `${newImage.urls.raw}&w=1366&q=70&auto=format` // https://unsplash.com/documentation#supported-parameters
				: newImage;

			if (this.activeModalSettings.type === 'element') {
				this.setElementData({ data: { settings: { image: imageUrl } } });
				this.closeModal();

				return;
			}

			this.$emit('select-image', imageUrl);
		},
		loadMoreFromUnsplash() {
			const imageContainer = this.$refs.imagesContainer;

			if (
				this.isLoading
				|| !this.searchTerm
				|| this.unsplashResults.total_pages <= this.currentPage
				|| imageContainer.offsetHeight
				+ imageContainer.scrollTop + 1400 < imageContainer.scrollHeight
			) {
				return;
			}

			this.currentPage += 1;
			this.isLoading = true;

			axios
				.post(
					`${process.env.VUE_APP_API_URL}/unsplash/search/`,
					{
						query: this.searchTerm,
						page: this.currentPage,
					},
					'application/json',
				)
				.then((resp) => {
					this.unsplashResults.results = [
						...this.unsplashResults.results,
						...resp.data.results,
					];
					if (resp.data.results.length === 0) {
						this.currentPage = this.unsplashResults.total_pages;
					}
				})
				.catch(() => {
					// TODO: Proper error handling
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		async uploadToS3(event, image) {
			const data = new FormData();
			const file = image ? image.files[0] : this.$refs.userImage.files[0];
			const exifOrientationFix = await loadImage(file, {
				canvas: true,
				orientation: true,
				meta: true,
			});
			const excludedFormats = [
				'svg',
				'gif',
				'webp',
			];
			const fileFormat = getExtension(file.name);
			const isFormatExcluded = fileFormat.includes(excludedFormats);
			const getFormatToConvertTo = () => {
				if (fileFormat === 'jpg') {
					return 'jpeg';
				}

				return isFormatExcluded ? 'png' : fileFormat;
			};

			exifOrientationFix.image.toBlob((blob) => {
				data.append('image', isFormatExcluded || !exifOrientationFix.exif ? file : blob, file.name);
				// Show preview
				const reader = new FileReader();

				this.$refs.uploaderPlaceholder.style.backgroundImage = '';
				reader.addEventListener('load', (loadEvent) => {
					this.$refs.uploaderPlaceholder.style.backgroundImage = `url(${loadEvent.target.result})`;
				});
				reader.readAsDataURL(file);

				// Upload
				this.isLoading = true;
				axios
					.post(`${process.env.VUE_APP_API_URL}/v1/assets`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
					.then((result) => {
						this.userResults.push(result.data[0].url);
						this.selectImage(result.data[0].url);
					})
					.catch((error) => {
						this.uploadError = error.response.data.msg;
					})
					.finally(() => {
						this.isLoading = false;
					});
			}, `image/${getFormatToConvertTo()}`);
		},
		onUserImageDragEnter() {
			this.$refs.dropArea.classList.add('is-dragged-over');
		},
		onUserImageDragLeave() {
			this.$refs.dropArea.classList.remove('is-dragged-over');
		},
		onUserImageDrop(event) {
			this.$refs.dropArea.classList.remove('is-dragged-over');
			const data = event.dataTransfer;

			this.uploadToS3(event, data);
		},
		openFileDialog() {
			this.$refs.userImage.click();
		},
	},
};
</script>

<style lang="scss" scoped>
// Update typography when this is refactored
.asset-manager {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 50px 45px;

	.title {
		display: block;
		margin-bottom: 20px;
		font-size: 31px;
	}

	.header {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	.search-box {
		display: flex;
		flex: 0 0 calc(66% - 23px);
		align-items: center;
		padding: 1px 1em 1px 1.3em;
		font-size: 12px;
		font-weight: 500;
		line-height: 1;
		background: rgb(245, 244, 244);
		border: 1px solid white;
		border-radius: 100px;
		transition: background-color 0.1s linear, border 0.15s linear;

		// TODO: use component
		input {
			display: block;
			width: 100%;
			padding: 1em 1em 1em 0.75em;
			font-size: 12px;
			line-height: 1;
			color: #111;
			background: rgb(245, 244, 244);
			border: none;
			border-radius: 100px;
			transition: background-color 0.1s linear;

			&::placeholder {
				color: #979797;
				user-select: none;
			}

			&:focus {
				outline: none;
			}
		}

		&.is-focused {
			border: 1px solid $grey-100;

			&,
			input {
				background: white;
			}
		}

		&:hover {
			border: 1px solid $grey-100;
		}

		.icon {
			width: initial;
			height: 12px;
			user-select: none;
		}

		.clear-input {
			padding-right: 5px;
			font-size: 1.7em;
			font-weight: 300;
			color: #979797;
			cursor: pointer;
			user-select: none;
		}
	}

	.images-zyro-section {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden;
	}

	.upload-error {
		display: flex;
		align-items: center;
		padding: 8px;
		margin-bottom: 8px;
		font-size: 14px;
		color: $primary;
		background-color: $secondary-lighter;
		border-radius: 5px;

		&-icon {
			width: 20px;
			height: 20px;
			margin-right: 8px;
			fill: $primary;
		}
	}

	.images-zyro {
		display: flex;

		.images {
			display: block;
			// Masonry layout messes up the padding a little
			flex: 0 0 calc(66% - 34px);
			align-self: center;
			height: 100%;
			overflow-y: scroll;
			font-size: 64px;
			color: rgba(0, 0, 0, 0.15);
			text-align: center;
		}

		.uploader {
			box-sizing: content-box;
			display: flex;
			flex: 0 0 33%;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			padding-right: 23px;
			margin-right: 23px;
			cursor: pointer;
			border-right: 1px solid $dark;

			&-placeholder {
				display: flex;
				flex: 0 0 calc(100% - 60px);
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				background: #f8f8f8;
				background-position: center center;
				background-size: cover;
				border: 1px solid transparent;
				transition: all 0.2s ease-out;

				svg {
					transition: all 0.2s ease-out;
				}
			}

			&-text,
			&-subtext {
				font-size: 10px;
				line-height: 1.5;
			}

			&-text {
				margin-top: 1em;
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 0.1em;
			}

			&-subtext {
				color: #9b9b9b;
			}

			&-button {
				margin-top: auto;
				cursor: pointer;
			}
		}
	}

	.images-unsplash,
	.images-zyro {
		flex: 1 1 100%;
		overflow: auto;

		&.is-dragged-over {
			.uploader-placeholder {
				background-color: darken(#f8f8f8, 1);
				border-color: $grey-100;

				svg {
					transform: translateY(-5px);
				}
			}
		}

		.user-loader {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 1;
		}

		&.is-loading {
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				content: '';
				background: rgba(0, 0, 0, 0.25);
			}
		}

		.image {
			position: relative;
			margin-bottom: 5px;

			&-select {
				position: absolute;
				display: flex;
				width: 100%;
				height: 100%;

				button {
					padding: 13px 28px;
					margin: auto;
					font-size: 9px;
					font-weight: 500;
					color: $dark;
					text-transform: uppercase;
					letter-spacing: 1.8px;
					cursor: pointer;
					background: white;
					border: none;
					border-radius: 3em;
				}
			}

			img {
				width: 100%;
				vertical-align: bottom;
			}
		}

		.image-info {
			position: absolute;
			bottom: 0;
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.3);
			opacity: 0;
			transition: opacity 0.2s ease-out;
			will-change: opacity;

			.author {
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 100%;
				padding: 12px;
				margin-top: auto;
				font-size: 10px;
				font-weight: 500;
				text-decoration: none;

				&-container {
					color: $grey-100;
				}

				&-thumb {
					flex: 0 0 15px;
					align-self: center;
					margin-right: 5px;
					border-radius: 25px;
				}

				&-name {
					display: block;
				}
			}

			&:hover {
				opacity: 1;
			}
		}
	}

	.images-unsplash {
		overflow-y: scroll;

		.loader {
			margin-top: 25px;
		}
	}

	.no-images {
		width: 100%;
		font-size: 13px;
		color: $dark;

		.search-example {
			padding-bottom: 3px;
			cursor: pointer;
			border-bottom: 1px solid $dark;
		}
	}

	.log-in {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		background-color: #f8f8f8;

		&__message {
			max-width: 192px;
			margin-bottom: 30px;
			font-size: 14px;
			text-align: center;
		}

		&__button {
			margin: 0 10px;
		}
	}

	.unsplash-attribution {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		user-select: none;

		.text {
			margin-right: 5px;
		}

		.unsplash-icon {
			max-width: 75px;
		}

		&-container {
			position: absolute;
			bottom: -5px;
			left: 0;
			width: 100%;
			padding: 0 45px 20px;
			font-size: 12px;
			line-height: 1;

			.tooltip {
				top: 21px;
				right: -20px;
				opacity: 0;
			}
		}

		.unsplash-logo {
			flex: 0 0 75px;
			max-height: 1.1em;
			cursor: pointer;
		}
	}
}

.tabs {
	.tab {
		padding: 0;
		margin: 0 4em 0 0;
		font-size: 9px;
		font-weight: 500;
		color: $dark;
		text-transform: uppercase;
		cursor: pointer;
		background-color: white;
		border: none;
		outline: none;

		&.is-active {
			padding-bottom: 3px;
			color: $secondary;
			border-bottom: 1px solid $secondary;
		}
	}
}

.unsplash-tooltip {
	position: absolute;
	max-width: 250px;
	padding: 17px;
	font-size: 11px;
	font-weight: 300;
	line-height: 1.4;
	color: $dark;
	letter-spacing: 0.01em;
	background: white;
	box-shadow: -1px 0 8px 0 rgba(0, 0, 0, 0.12);

	&-paragraph {
		margin: 0;
	}

	&-link {
		padding-bottom: 2px;
		margin-bottom: 2px;
		color: $dark;
		text-decoration: none;
		border-bottom: 1px solid $dark;
	}
}

@media screen and (max-width: $media-mobile) {
	.asset-manager {
		padding: 15px 0;
	}
}
</style>
